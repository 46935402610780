<template>
  <div v-if="showAdd" class="background">
    <div class="popup">
      <input placeholder="username" v-model="username" type="text" />
      <input placeholder="password" v-model="password" type="password" />
      <div>
        <button @click="cancelAdd" class="neutral">CANCEL</button>
        <button @click="confirmAdd" class="danger">CONFIRM</button>
      </div>
    </div>
  </div>
</template>

<script>
import global from '../composables/global';
import settings from '../composables/settings';

export default {
  setup() {
    return {
      showAdd: settings.showAdd,
      confirmAdd: settings.confirmAdd,
      cancelAdd: settings.cancelAdd,
      username: settings.confirmBoxUsername,
      password: settings.confirmBoxPassword,
    };
  },
};
</script>

<style lang="scss" scoped>
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 99vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.404);

  .popup {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -200px;
    background: #dfdfdf;
    width: 400px;
    height: 200px;
    border-radius: 6px;
    box-shadow: 0 0 50px 20px black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    input {
      color: #3a3a3a;
      font-size: 2em;
      padding-bottom: 10px;
      margin: 10px 32px;
      background: #dfdfdf;
      border-bottom: 2px solid #3a3a3a;
    }
    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      button {
        margin: 15px;
      }
    }
  }
}
</style>
