<template>
  <div class="main-panel">
    <div class="settings">
      <h1>Settings</h1>
      <div>
        <input
          v-if="currentUser"
          type="checkbox"
          @click="changeSetting($event, 'showpreview', currentUser.userid)"
          :checked="currentUser.showpreview"
          name="preview"
        />
        <label for="preview">Show Preview on Dashboard</label>
      </div>
    </div>

    <div class="users">
      <h1>Users</h1>
      <div>
        <div class="user" v-for="user in globalState.users" :key="user.userid">
          <span>{{ user.username }}</span>
          <div>
            <img @click="openAdd(user.userid)" class="action-btn" src="../assets/lock.svg" alt="" />
            <img
              @click="
                setAlert('Are you sure you want to delete this user?', 'CANCEL', 'DELETE', 'deleteuser', user.userid)
              "
              class="action-btn"
              src="../assets/discard.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      <img class="action-btn" @click="openAdd('')" src="../assets/plus-slim.svg" alt="" />
    </div>
  </div>
  <UserInfo />
</template>

<script>
import settings from '../composables/settings';
import global from '../composables/global';
import UserInfo from '../components/UserInfo.vue';

export default {
  components: { UserInfo },
  setup() {
    global.getUsers();

    return {
      globalState: global.state,
      showAdd: settings.showAdd,
      openAdd: settings.openAdd,
      setAlert: global.setAlert,
      currentUser: global.currentUser,
      changeSetting: settings.changeSetting,
    };
  },
};
</script>

<style lang="scss" scoped>
.settings,
.users {
  width: 400px;
  h1 {
    border-bottom: 2px solid #dfdfdf;
    width: 100%;
    margin-bottom: 15px;
  }
  div {
    margin-left: 25px;
    input {
      width: 15px;
      height: 15px;
      margin: 0 auto;
    }
    label {
      line-height: 14px;
      margin: 15px;
      vertical-align: top;
    }
  }
}

.users {
  margin-top: 100px;
  div {
    margin-left: 0px;
    .user {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 7px 15px;
      img {
        width: 15px;
        margin-left: 15px;
      }
    }
  }
  & > img {
    width: 20px;
    margin-top: 12px;
  }
}
</style>
